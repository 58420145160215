import { useGetSchoolAccountQuery } from 'api/schoolGroups.api';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  GROUP_ADDITIONAL_SCHOOL_PRICE,
  GROUP_INTITIAL_PRICE,
  PaymentStatus,
  SchoolGroupStructureType,
} from 'searchality-data';
import DateService from 'services/Date.service';
import { numberPriceToCurrencyString } from 'utils';
import { useSchoolPrices } from '../../../../../hooks/useSchoolPrices';

const useCard = () => {
  const { schoolGroupId } = useParams();
  const { data: schoolGroup } = useGetSchoolAccountQuery(schoolGroupId);

  const {
    structureType,
    schools,
    isSubscriptionCancelledAtPeriodEnd,
    subscriptionId,
    subscriptionEndDate,
    subscriptionStartDate,
    paymentStatus,
    hasOldPricingSystem,
  } = schoolGroup;

  const { schoolPrice } = useSchoolPrices(
    structureType,
    schools,
    hasOldPricingSystem,
  );

  const { t } = useTranslation();

  const subscriptionStatus = useMemo(() => {
    if (subscriptionId && !isSubscriptionCancelledAtPeriodEnd) {
      return 'active';
    }

    return 'inactive';
  }, [isSubscriptionCancelledAtPeriodEnd, subscriptionId]);

  const standalone = useMemo(
    () => [
      {
        label: t('SubscriptionCard.totalCostLabel'),
        value: t('SubscriptionCard.totalCostValue', {
          price: numberPriceToCurrencyString(schoolPrice),
        }),
      },
    ],
    [schoolPrice, t],
  );

  const group = useMemo(
    () => [
      {
        label: t('SubscriptionCard.totalCostLabel'),
        value: t('SubscriptionCard.totalCostValue', {
          price: numberPriceToCurrencyString(schoolPrice),
        }),
        description: t('SubscriptionCard.totalCostDescription', {
          basePrice: numberPriceToCurrencyString(GROUP_INTITIAL_PRICE),
          additionalPrice: numberPriceToCurrencyString(
            GROUP_ADDITIONAL_SCHOOL_PRICE,
          ),
          numOfSchools: 2,
        }),
      },
      {
        label: t('SubscriptionCard.ammountOfSchoolTitle'),
        value: t('SubscriptionCard.ammountOfSchoolDescription', {
          count: schools?.length,
        }),
      },
      {
        label: t('SubscriptionCard.costPerAdditionalSchool'),
        value: numberPriceToCurrencyString(
          schools.length > 2
            ? (schools.length - 2) * GROUP_ADDITIONAL_SCHOOL_PRICE
            : 0,
        ),
      },
    ],
    [schoolPrice, schools.length, t],
  );

  const billing = useMemo(
    () => [
      {
        label: t('SubscriptionCard.subscriptionStatus'),
        value: t(subscriptionStatus),
        className: subscriptionStatus === 'active' ? 'blue' : 'orange',
      },
      {
        label: t('SubscriptionCard.paymentStatus'),
        value: t(paymentStatus),
        className: paymentStatus === PaymentStatus.PAID ? 'blue' : 'orange',
      },
      ...(subscriptionStartDate
        ? [
            {
              label: t('SubscriptionCard.billingPeriodStart'),
              value: DateService.getUSADateFormatLongWithTime(
                subscriptionStartDate,
              ),
            },
          ]
        : []),
      ...(subscriptionEndDate
        ? [
            {
              label: t('SubscriptionCard.nextPaymentOn'),
              value: !isSubscriptionCancelledAtPeriodEnd
                ? DateService.getUSADateFormatLongWithTime(subscriptionEndDate)
                : '-',
            },
          ]
        : []),
    ],
    [
      isSubscriptionCancelledAtPeriodEnd,
      paymentStatus,
      subscriptionEndDate,
      subscriptionStartDate,
      subscriptionStatus,
      t,
    ],
  );
  return structureType === SchoolGroupStructureType.GROUP
    ? [...group, ...billing]
    : [...standalone, ...billing];
};

export default useCard;
